/*
** Variables **
*/

:root {
  --color-green: #39b54a;
  --color-green-hover: #209c31;
  --color-cyan: #00abbd;
  --color-cyan-hover: #0092a4;
  --color-yellow: #eee809;
  --color-yellow-hover: #d5cf00;
  --color-orange: #f5a623;
  --color-orange-hover: #dc8d0a;
  --color-red: #ef5034;
  --color-red-hover: #d6371b;
  --color-grey-1: #252628;
  --color-grey-2: #58595b;
  --color-grey-3: #bebfc4;
  --color-grey-4: #d7d8de;
  --color-grey-5: #ebebef;
  --color-grey-6: #f1f2f6;
  --color-white: #ffffff;

  --line-height-xs: 0.9;
  --line-height-m: 1.2;
  --line-height-l: 1.6;

  --font-size-xs: 13px;
  --font-size-s: 15px;
  --font-size-m: 18px;
  --font-size-l: 22px;
  --font-size-xl: 26px;
  --font-size-xxl: 31px;
  --font-size-xxxl: 45px;
  --font-size-xxxxl: 54px;
  --font-size-xxxxxl: 64px;
  --font-size-xxxxxxl: 77px;
  --font-size-xxxxxxxl: 90px;

  --font-weight-regular: 400;
  --font-weight-semi: 500;
  --font-weight-bold: 700;

  --font-family-compressed: "franklin-gothic-urw-comp", sans-serif;
  --font-family-condensed: "franklin-gothic-urw-cond", sans-serif;
  --font-family: "franklin-gothic-urw", sans-serif;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --transition: 0.2s ease-out;

  --border-radius: 3px;
  --border-radius-m: 4px;
  --border-radius-l: 6px;

  --z-index-navigation: 900;
  --z-index-messageBar: 9000;
}
