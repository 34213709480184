@design-tokens url('../utils/media-queries.json') format('style-dictionary3');

@import "../styles/_variables.css";
@import "../styles/_snipcart.css";

::-moz-selection {
  background: var(--color-green);
  color: var(--color-white);
  background: #39b54a;
  color: #ffffff;
}

::selection {
  background: var(--color-green);
  color: var(--color-white);
  background: #39b54a;
  color: #ffffff;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: var(--color-grey-1);
  font-family: var(--font-family);
  font-size: var(--font-size-m);
  font-smoothing: antialiased;
  font-variant-numeric: lining-nums;
  margin: 0;
  padding: 77px 0 0; /* Fixed header */

  @media (design-tokens("--viewport-l")) {
    padding: 80px 0 0; /* Fixed header */
  }
}

div:focus {
  outline: 0;
}
